.dialog_overlay {
	opacity: 0.75;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: Canvas;
}

.dialog {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10;
}

.dialog .top {
	display: flex;
	width: 100%;

	& .close {
		display: flex;
		align-items: center;
	}

	& h2 {
		flex: 1;
	}
}