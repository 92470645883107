.job-div {
	display: grid;
	grid-template-columns: max-content auto;
	grid-column-gap: .5em;
	grid-row-gap: .2em;
}

.job-div label {
	display: contents;
}

button {
	grid-column: 1 / 3;
}
